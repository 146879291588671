@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

.App {
    font-family: 'Ubuntu', sans-serif;
}

.subtleTextArea {
    overflow: auto;
    resize: none;

    background: rgba(0, 0, 0, 0);
    border: none;
    outline: 0;
    color: black;
    font-family: 'Ubuntu', sans-serif;
    font-size: medium;
}