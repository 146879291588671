.gutter {
    background-color: rgb(225, 225, 225);
    background-repeat: no-repeat;
    background-position: 50%;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg==');
}

.gutter.gutter-horizontal {
    cursor: col-resize;
    width: 10px;
    transition: background-color .2s;
}

.gutter.gutter-horizontal:hover {
    background-color: rgb(182, 182, 182);
}