.schemaPane {
    background-color: white;
    /* width: 240px; */
    z-index: 1; /* pop over arrows */
    overflow-y: auto;
}

.PropertySuggestionsCard {
    opacity: 1;
    transition: all 0.2s ease-out;
    transition-property: height, margin-top, transform;
    background-color: rgb(249, 249, 249);
}

.PropertySuggestionsCard:hover {
    opacity: 1;
}